<template>
  <v-container class="footer-address white--text">
    <v-row>
      <v-col cols="12" sm="6">
        <p class="mb-0">Zoologická zahrada hl. m. Prahy</p>
        <p class="mb-0">U Trojského zámku 120/3</p>
        <p class="mb-0">171 00 Praha 7</p>        
      </v-col>
      <v-col cols="12" sm="6" class="text-left text-sm-right">
        <p><a class="white--text" href="/ticket-validation">{{$t('footer.ticketValidation')}}</a></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FooterAddress",
};
</script>

<style lang="scss">
.footer-address {
  font-size: 12px;
}
</style>
