<template>
  <div id="app">
    <v-app>
      <nav-bar :isCheckout="isCheckout"></nav-bar>
      <v-main class="body-background">
        <router-view></router-view>
      </v-main>
      <app-footer :isCheckout="isCheckout" />
      <snackbar />
      <cookie primary-color="#e9531c" :lang="$i18n.locale" />
    </v-app>
  </div>
</template>

<script>
import Cookie from "./components/Cookie2022.vue";
import NavBar from "./components/System/NavBar.vue";
import Footer from "./components/Footer/Footer";
import Snackbar from "./components/System/Snackbar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "app",
  metaInfo: {
    title: "Zoo Praha",
    titleTemplate: "%s | Zoo Praha",
  },
  components: {
    NavBar,
    AppFooter: Footer,
    Snackbar,
    Cookie,
  },
  computed: {
    ...mapGetters(["allCategories"]),
  },
  data() {
    return {
      isCheckout: false,
    };
  },
  mounted() {
    this.isCheckout = this.$router.currentRoute.name.includes("Checkout");
    if (!this.allCategories.length) {
      this.retrieveCategories({ lang: this.$i18n.locale, langChange: false });
    }
  },
  watch: {
    "$route.name": function (val) {
      this.isCheckout = val === "Checkout";
    },
    "$i18n.locale": function (val) {
      this.retrieveCategories({ lang: val, langChange: true });
    },
  },
  methods: {
    ...mapActions(["retrieveCategories"]),
  },
};
</script>

<style lang="scss">
@media (min-width: 1904px) {
  .container {
    max-width: 1185px;
    &.container--fluid {
      max-width: 100%;
    }
  }
}

.v-application {
  font-family: 'News Gothic MT', sans-serif !important;
  .body-2,
  .display-1 {
    font-family: 'News Gothic MT', sans-serif !important;
  }
}
</style>
