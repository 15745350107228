<template>
  <v-container fluid class="page-bg pa-0">
    <v-container class="text-center py-0 pl-0 pr-0">
      <img width="100%" :src="bannerImage" />
      <div class="main-title pl-2 pr-2" :style="{ 'background-color': titleColor }">
        <h1>{{ title }}</h1>
      </div>
      <div class="mt-4 pl-2 pr-2" v-if="description" v-html="description"></div>
      <div v-if="showCategories">
        <v-btn @click="$router.push('/category/' + category.alias)" text :color="getButtonColor(category.alias)"
          v-for="category in allCategories" :key="category.id">{{ category.name }}</v-btn>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getProductImage } from "@/Helper";

export default {
  name: "CategoryHeader",
  props: ["showCategories"],
  mixins: [getProductImage],
  computed: {
    ...mapGetters(["currentCategory", "allCategories"]),
    title() {
      if (this.currentCategory) {
        return this.currentCategory.name;
      }

      return "Obchod";
    },
    titleColor() {
      if (this.currentCategory && this.currentCategory.customData && this.currentCategory.customData.backgroundColor) {
        return this.currentCategory.customData.backgroundColor;
      }

      return "#9b9c9c";
    },
    description() {
      if (this.currentCategory) {
        return this.currentCategory.description;
      }

      return null;
    },
    bannerImage() {
      if (this.currentCategory && this.currentCategory.customData && this.currentCategory.customData.banner) {
        return require(`@/assets/banners/${this.currentCategory.customData.banner}`);
      }
      return null;
    },
    activeItem() {
      if (this.currentCategory) {
        return this.currentCategory.alias;
      }

      return null;
    },
  },

  methods: {
    ...mapActions(["changeCategory"]),
    getButtonColor(alias) {
      if (alias === this.activeItem) {
        return "primary";
      }

      return "black";
    },
  },
};
</script>

<style lang="scss">
.page-bg {
  background-size: cover;
  background-position: center center;

  .main-title {
    display: table;
    height: 80px;
    width: 100%;
    margin-top: 0;
  }

  .main-title h1 {
    font-size: 1.5em;
    text-transform: uppercase;
    font-weight: 600;
    color: white;
    display: table-cell;
    vertical-align: middle;
    line-height: 1.1;
  }

  .v-btn__content {
    font-weight: bold;
  }

  img {
    vertical-align: bottom;
  }
}
</style>
