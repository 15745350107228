<template>    
    <image-category-promo />    
</template>

<script>
    import ImageCategoryPromo from "../components/HomePage/ImageCategoryPromo";

    export default {
        name: 'Homepage',
        components: {
            ImageCategoryPromo
        }
    }
</script>
