<template>
  <v-layout justify-space-between column fill-height>
    <div>
      <v-toolbar flat>
        <v-toolbar-title class="text-uppercase font-weight-bold">{{
          $t("homepage.shoppingCart")
        }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <div v-if="!cartProducts.length">
        <div class="text-center pa-5">
          <v-icon class="empty-cart"> mdi-cart-remove </v-icon>
          <div class="py-5">
            <p class="font-weight-bold text-uppercase">
              {{ $t("cart.noProducts") }}
            </p>
          </div>
        </div>
      </div>
      <v-list v-if="cartProducts && cartProducts.length && cartProducts[0] !== null">
        <v-list-item v-for="(item, index) in cartProducts" :key="index">
          <v-list-item-avatar>
            <v-img :src="getProductImage(item.imageUrl)"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              v-text="item.name"
              class="font-weight-bold"
            ></v-list-item-title>
            <v-list-item-subtitle
              >{{ item.qty }} x
              <span class="primary--text">{{
                $t("homepage.currency", {
                  price: formatThousands(item.amountWithVat),
                })
              }}</span></v-list-item-subtitle
            >
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon small @click="removeFromCart(index)">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>
    <div v-if="cartProducts.length">
      <v-divider class="pb-4"></v-divider>
      <p
        class="d-flex justify-space-between align-center px-4"
        v-if="user && user.discount"
      >
        <strong class="text-uppercase">{{ $t("cart.discount") }}:</strong
        ><span class="primary--text total-amount font-weight-bold">{{
          $t("homepage.currency", {
            price: formatThousands(discount),
          })
        }}</span>
      </p>
      <p class="d-flex justify-space-between align-center px-4">
        <strong class="text-uppercase">{{ $t("cart.subtotal") }}:</strong>
        <span
          v-if="user && discount"
          class="primary--text total-amount font-weight-bold"
          >{{
            $t("homepage.currency", {
              price: formatThousands(totalDiscount),
            })
          }}</span
        >
        <span v-else class="primary--text total-amount font-weight-bold">{{
          $t("homepage.currency", {
            price: formatThousands(total),
          })
        }}</span>
      </p>
      <p class="px-4">
        <v-btn
          color="grey lighten-3"
          class="mb-2"
          tile
          depressed
          block
          @click="$router.push('/cart')"
          >{{ $t("cart.showCart") }}</v-btn
        >
        <v-btn
          color="primary"
          tile
          depressed
          block
          @click="$router.push('/checkout')"
          >{{ $t("cart.checkout") }}</v-btn
        >
      </p>
    </div>
  </v-layout>
</template>

<script>
import { formatThousands, getProductImage } from "../../Helper";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CartOverlay",
  mixins: [formatThousands, getProductImage],
  computed: {
    ...mapGetters(["cartProducts", "user"]),
    total() {
      if (this.cartProducts[0] === null) return null;
      return this.cartProducts.reduce((total, p) => {
        return total + p.amountWithVat * p.qty;
      }, 0);
    },
    totalDiscount() {
      if (this.cartProducts[0] === null) return null;
      let total = this.cartProducts.reduce((total, p) => {
        return total + p.amountWithVat * p.qty;
      }, 0);

      if (this.discount) {
        return parseInt(total) - this.discount;
      }

      return total;
    },
    discount() {
      if (this.user && this.user.discount) {
        return Math.round(this.total * this.user.discount);
      }

      return false;
    },
  },
  methods: {
    ...mapActions(["removeFromCart"]),
    close() {
      this.$emit("drawerClose");
    },
  },
};
</script>

<style scoped lang="scss">
.v-list-item__title {
  font-size: 14px;
}

.empty-cart {
  font-size: 86px !important;
  color: rgba(135, 135, 135, 0.15) !important;
}
.total-amount {
  font-size: 20px;
}
</style>
