import Vue from 'vue'
import Router from 'vue-router'
import VueMeta from 'vue-meta'

import Homepage from '../pages/Homepage'
import Category from '../pages/Category'
import Product from '../pages/Product'
import CheckCustomer from '../pages/CheckCustomer'
import CheckPass from '../pages/CheckPass'
import Checkout from '../pages/Checkout'
import CheckoutSuccess from "../pages/CheckoutSuccess";
import Cart from '../pages/Cart'
import PaymentFeedback from '../pages/PaymentFeedback'
import CookiePolicy from '../pages/CookiePolicy'
import SeasonTicketRegistration from '../pages/SeasonTicketRegistration'

Vue.use(VueMeta);
Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        { path: '/', component: Homepage, name: 'Homepage' },
        { path: '/category/:alias', component: Category, name: 'Category' },
        { path: '/product/:alias', component: Product, name: 'Product' },
        { path: '/check-customer', component: CheckCustomer, name: 'CheckingCustomer' },
        { path: '/ticket-validation', component: CheckPass, name: 'CheckingPass' },
        { path: '/cart', component: Cart, name: 'Cart' },
        { path: '/checkout', component: Checkout, name: 'Checkout' },
        { path: '/checkout/success', component: CheckoutSuccess, name: 'Success' },
        { path: '/payment/feedback/:eshopId/', component: PaymentFeedback, name: 'PaymentFeedback'},
        { path: '/privacy', component: CookiePolicy, name: "CookiePolicy"},
        { path: '/neprenosne-permanentky/registrace/:id', component: SeasonTicketRegistration, name: 'CheckoutRegistration'}
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})
