<template>
  <v-container grid-list-lg>
    <v-layout row wrap v-if="loadingCategories">
      <v-flex xs12 sm6 lg3 v-for="n in 3" :key="n">
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="category-products mb-4 justify-center">
      <v-flex v-for="product in allProducts" :key="product.id">
        <v-card flat class="text-center transparent">
          <div class="product-title-container">
            <img width="320" class="cursor-pointer" :src="getProductImage(product.imageUrl)"
              @click="$router.push('/product/' + product.alias)" />
            <h3 v-if="product.imageUrl" class="product-title cursor-pointer" @click="$router.push('/product/' + product.alias)">{{
              product.name }}</h3>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getProductImage } from "@/Helper";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Products",
  mixins: [getProductImage],
  computed: {
    ...mapGetters(["allProducts", "loadingCategories"]),
  },
  watch: {
    allProducts: function (newValue) {
      if (newValue) {
        if (this.$gtm.enabled()) {
          let products = [];

          for (let i in this.allProducts) {
            products.push({
              id: this.allProducts[i].productId,
              name: this.allProducts[i].name,
              price: this.allProducts[i].amountWithVat,
              category: this.allProducts[i].productCategoryName,
              list: "Home Page",
              position: i + 1,
            });
          }
          this.$gtm.trackEvent({
            event: "productImpression", // Event type [default = 'interaction'] (Optional)
            category: "Ecommerce",
            ecommerce: {
              impressions: products,
            },
          });
        }
      }
    },
  },
  methods: {
    ...mapActions(["addToCart"]),
  },
};
</script>

<style lang="scss">
.category-products {
  .cart-button-wrapper {
    margin-top: -10px;
  }

  .cart-button {
    text-transform: initial;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .cart-image {
    width: 30px;
  }

  .product-title-container {
    position: relative;
    width: 320px;
    margin: auto;
  }

  .product-title-container img {
    vertical-align: bottom;
  }

  .product-title {
    text-align: left;
    position: absolute;
    bottom: 30px;
    left: 10px;
    width: 120px;
    text-transform: uppercase;
    color: white;
    font-weight: 550;
    font-size: 1rem;
  }
}
</style>
