<template>
    <v-container fluid class="navbar py-0 black" :class="{'isCheckout': isCheckout}">
        <MainHeader :isCheckout="isCheckout" />
    </v-container>
</template>

<script>
    import MainHeader from "../Header/MainHeader";

    export default {
        name: 'NavBar',
        props: ['isCheckout'],
        components: {
            MainHeader
        }
    }
</script>
