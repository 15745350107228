<template>
  <div class="product-detail">
    <v-skeleton-loader type="article" v-if="loadingCategories || !currentProduct"></v-skeleton-loader>
    <div v-else>
      <h1 class="text-uppercase">{{ currentProduct.name }}</h1>
      <p class="price font-weight-bold mt-2 mb-4">
        {{
          $t("homepage.currency", {
            price: formatThousands(currentProduct.amountWithVat),
          })
        }}
      </p>
      <p v-html="currentProduct.description" class="product-description mb-6 text-justify"></p>

      <v-form @submit.prevent ref="form">
        <v-text-field v-if="currentProduct.isGiftCard" ref="giftCardText" v-model="giftCardText"
          :label="$t('cart.giftCardText')" :rules="[rules.required]" counter maxlength="50" type="text"
          class="mb-2"></v-text-field>
      </v-form>

      <v-layout class="mb-3 ml-0">
        <vue-numeric-input v-if="!currentProduct.isGiftCard" v-model="qty" :min="1" :max="currentProduct.maxOrderUnits" align="center" size="100px"
          class="ma-0 mr-3"></vue-numeric-input>
        <v-btn class="white--text" color="black" tile depressed
          @click="addToCartInternal({ product: currentProduct, qty: qty, giftCard: giftCard })">{{
            $t("cart.addToCart") }}</v-btn>
      </v-layout>
    </div>
  </div>
</template>

<script>
import VueNumericInput from "vue-numeric-input";
import { mapGetters, mapActions } from "vuex";
import { formatThousands } from "@/Helper";

export default {
  name: "ProductDetail",
  mixins: [formatThousands],
  computed: {
    ...mapGetters(["currentProduct", "currentCategory", "loadingCategories"]),
    giftCard() {
      if (!this.currentProduct.isGiftCard)
        return null;

      return { name: null, text: this.giftCardText };
    }
  },
  watch: {
    giftCardText: function (val) {
      if (!val)
        return val;

      this.giftCardText = val.toUpperCase();
    },
  },
  components: {
    VueNumericInput,
  },
  data() {
    return {
      rules: window.validationRules.getRules(),
      qty: 1,
      giftCardText: null
    };
  },
  methods: {
    ...mapActions(["addToCart"]),
    addToCartInternal(obj) {
      const form = this.$refs.form;

      if (form.validate()) {
        this.addToCart(obj);
        this.giftCardText = null;
        form.resetValidation();
      } else {
        this.$refs.giftCardText.focus();
      }
    }
  },
};
</script>

<style lang="scss">
.product-detail {
  .v-toolbar__content {
    padding-left: 0;
  }

  .product-description img {
    width: 100%;
    width: -moz-available;
    /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    width: fill-available;
  }

  .product-description {
    span {
      display: block;
      color: #777;

      &:first-child {
        margin-bottom: 20px;
        color: #000000;
      }
    }
  }

  .price {
    font-size: 22px;
  }

  .vue-numeric-input {
    input {
      outline: none;
      height: 100%;
    }

    .btn {
      background: transparent;
      box-shadow: none;
      border: none;

      &:hover {
        background: #9b9c9c;
      }
    }
  }
}
</style>
