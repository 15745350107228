<template>
  <div>
    <v-container grid-list-lg class="mt-7 mb-4 pb-10">
      <v-row>
        <v-col cols="12" sm="6" class="mx-auto">
          <h2>{{ $t("seasonTicket.title") }}</h2>
          <v-row
            align-content="center"
            justify="center"
            class="py-5"
            v-if="loadingOrderId"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
          <v-form
            v-model="valid"
            ref="registerForm"
            lazy-validation
            @submit.prevent="handleFormSubmit"
            @keyup.native.enter="register()"
            v-if="passHolders"
          >
            <div v-for="(i, index) in passHolders" :key="index">
              <v-row>
                <v-col cols="12">
                  <h4>{{ $t("seasonTicket.person") }} {{ index + 1 }}</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :readonly="isReadOnly"
                    :label="$t('seasonTicket.name')"
                    filled
                    v-model="i.firstName"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :readonly="isReadOnly"
                    :label="$t('seasonTicket.surname')"
                    filled
                    v-model="i.lastName"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :readonly="isReadOnly"
                    :label="$t('seasonTicket.city')"
                    filled
                    v-model="i.city"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :readonly="isReadOnly"
                    :label="$t('seasonTicket.birthday')"
                    filled
                    v-model="i.birthYear"
                    :rules="[rules.required]"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-row v-if="passHolders && !isReadOnly">
              <v-col cols="12">
                <v-btn
                  block
                  depressed
                  tile
                  color="primary"
                  :loading="loading"
                  :disabled="loading"
                  @click="register()"
                  >{{ $t("seasonTicket.register") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ValidationRules from "../utilities/ValidationRules";

export default {
  name: "SeasonTicketRegistration",
  mounted() {
    let orderItemId = this.$router.currentRoute.params.id;    
    if (typeof orderItemId === "undefined" || !orderItemId.length) {
      this.$router.push("/");
      return;
    }

    this.loadingOrderId = true;
    window.axios.get(
            process.env.VUE_APP_ROOT_API +
            "/api/order/item/" + orderItemId + "/pass-holders")
          .then((response) => {
            this.passHolders = response.data.passHolders;
            this.isReadOnly = response.data.isReadOnly;
            this.loadingOrderId = false;
          })
          .catch((error) => {
            this.loadingOrderId = false;
            console.log(error);
          });

    this.orderItemId = orderItemId;    
  },
  data() {
    return {
      loadingOrderId: false,
      orderItemId: null,      
      passHolders: [],
      isReadOnly: false,
      valid: true,
      loading: false,
      rules: ValidationRules.getRules(),
    };
  },
  methods: {
    ...mapActions(["handleFormSubmit", "showSuccessSnackbar"]),
    register() {
      if (this.$refs.registerForm.validate()) {
        this.loading = true;   
        window.axios
          .patch(
            process.env.VUE_APP_ROOT_API +
            "/api/order/item/" + this.orderItemId + "/pass-holders",              
            {
              passHolders: this.passHolders,
            }
          )
          .then((response) => {
            this.onSuccess(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    onSuccess(response) {
      console.log(response);
      this.loading = false;
      if (response) {        
        setTimeout(function() 
        {                         
            window.location.replace(decodeURI(response.data));
        }, 3000);                    
      } else {
        this.$router.push("/");
        this.showSuccessSnackbar({
          message: this.$t("seasonTicket.registerSuccess"),
          button: false,
        });
      }
    },
  },
};
</script>
