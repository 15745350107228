<template>
    <div>
        <v-container fluid class="page-bg pa-0">
            <v-container class="text-center py-0 pl-0 pr-0">
                <img width="100%" :src="require('../../assets/banners/homepage.jpg')" />
                <div class="main-title pl-2 pr-2">
                    <h1>{{ $t("homepage.title") }}</h1>
                </div>
                <div class="mt-4 pl-2 pr-2">{{ $t("homepage.subTitle") }}</div>
            </v-container>
        </v-container>
        <v-container grid-list-lg>
            <v-layout row wrap v-if="loadingCategories">
                <v-flex xs12 sm6 lg3 v-for="n in 3" :key="n">
                    <v-skeleton-loader type="card"></v-skeleton-loader>
                </v-flex>
            </v-layout>
            <v-layout row wrap class="category-promo mb-4 justify-center">
                <v-flex v-for="category in allCategories" :key="category.id">
                    <v-card flat class="text-center transparent">
                        <div class="product-title-container">
                            <img width="320" class="cursor-pointer" :src="getProductImage(category.imageUrl)"
                                @click="$router.push(getCategoryUrl(category))" />
                            <h3 class="product-title cursor-pointer" @click="$router.push(getCategoryUrl(category))">{{
                                category.name }}</h3>
                        </div>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import { getProductImage } from "@/Helper";
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'Promo',
    data() {
        return {

        }
    },
    mixins: [getProductImage],
    computed: {
        ...mapGetters([
            "loadingCategories",
            "allCategories"
        ]),
        imageUrl() {
            return process.env.VUE_APP_ROOT_API;
        }
    },
    methods: {
        ...mapActions([
            'addToCart'
        ]),
        getCategoryUrl(category) {
            return '/category/' + category.alias;
        }
    }
}
</script>

<style lang="scss">
.category-promo {
    .product-title-container {
        position: relative;
        width: 320px;
        margin: auto;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .product-title-container img {
        vertical-align: bottom;
    }

    .product-title {
        text-align: left;
        position: absolute;
        bottom: 30px;
        left: 10px;
        width: 120px;
        text-transform: uppercase;
        color: white;
        font-weight: 550;
        font-size: 1rem;
    }
}

.page-bg {
    background-size: cover;
    background-position: center center;

    .main-title {
        display: table;
        height: 80px;
        width: 100%;
        margin-top: 0;
        background-color: #9b9c9c;
    }

    .main-title h1 {
        font-size: 1.5em;
        text-transform: uppercase;
        font-weight: 600;
        color: white;
        display: table-cell;
        vertical-align: middle;
        line-height: 1.1;
    }

    .v-btn__content {
        font-weight: bold;
    }

    img {
        vertical-align: bottom;
    }
}
</style>

