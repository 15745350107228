<template>
    <div>
        <v-container fluid class="page-bg pa-0">
            <v-container class="text-center py-0 pl-0 pr-0">
                <div class="main-title pl-2 pr-2" :style="{ 'background-color': '#f5be00' }">
                    <h1>{{ $t("passValidation.title") }}</h1>
                </div>
            </v-container>
        </v-container>
        <v-form v-model="valid" ref="checkForm" lazy-validation @submit.prevent="validate"
            @keyup.native.enter="validate($event)">
            <v-container grid-list-lg>
                <v-layout row wrap>
                    <v-container class="mt-7">
                        <v-row class="d-flex justify-center align-center">
                            <v-col cols="12" sm="9">
                                <v-text-field v-model="number" :label="$t('passValidation.passNumber')" required
                                    :rules="[rules.required]" @keydown.enter="validate()"
                                    :maxlength="50"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex justify-center align-center">
                            <v-col cols="12" sm="9">
                                <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired"
                                    size="invisible" :loadRecaptchaScript="true" :sitekey="recaptchaSiteKey">
                                </vue-recaptcha>

                                <v-btn block depressed tile color="primary" :loading="checkingPass"
                                    :disabled="checkingPass" @click="validate()">{{ $t("passValidation.validateButton")
                                    }}</v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="errNotFound" class="d-flex justify-center align-center">
                            <v-col cols="12" sm="9">
                                <span class="error--text font-weight-middle">{{ $t("passValidation.passNotFound")
                                    }}</span>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container v-if="!errNotFound">
                        <v-row class="d-flex justify-center align-center">
                            <v-col cols="12" sm="9">
                                <v-container v-if="passValidationInfo.number" class="mt-7 mb-4 pb-10">
                                    <v-row>
                                        <v-col cols="6" sm="8">
                                            <b>{{ $t('passValidation.passNumber') }}:</b>
                                        </v-col>
                                        <v-col cols="6" sm="4">
                                            <span style="word-break: break-all;">
                                                {{ passValidationInfo.number }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row v-for="(item, index) in passValidationInfo.entries" :key="index">
                                        <v-col cols="6" sm="8">
                                            <v-icon>{{ item.code == 'Parking' ? 'mdi-car' : 'mdi-counter'
                                                }}</v-icon>&nbsp;{{
                    item.code == 'Parking' ? $t('passValidation.parkingCount') :
                        $t('passValidation.entriesCount') }}
                                        </v-col>
                                        <v-col cols="6" sm="4">{{ item.count }}</v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" sm="8">
                                            <v-icon>mdi-calendar-start</v-icon>&nbsp;{{
                    $t("passValidation.passValidFrom")
                }}
                                        </v-col>
                                        <v-col cols="6" sm="4">
                                            {{ this.validFrom }}
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" sm="8">
                                            <v-icon>mdi-calendar-end</v-icon>&nbsp;{{ $t("passValidation.passValidTo")
                                            }}
                                        </v-col>
                                        <v-col cols="6" sm="4">
                                            {{ this.validTo }}
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container>
                        <v-row class="d-flex justify-center align-center">
                            <v-col cols="12" sm="9">
                                <p class="text-caption"><span>{{ $t("passValidation.ticketInfo") }}</span>&nbsp;<a
                                        href="https://www.zoopraha.cz/navsteva/vstupne" target="_blank">{{
                                        $t("passValidation.ticketInfoAdmission") }}</a></p>
                                <p class="text-caption">{{ $t("passValidation.updateInfo") }}</p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-layout>
                <v-overlay :value="checkingPass">
                    <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
                </v-overlay>
            </v-container>
        </v-form>
    </div>
</template>

<script>
import ValidationRules from "../utilities/ValidationRules";
import VueRecaptcha from 'vue-recaptcha';

export default {
    name: 'CheckPass',
    components: { VueRecaptcha },
    data() {
        return {
            valid: true,
            checkingPass: false,
            number: null,
            errNotFound: false,
            rules: ValidationRules.getRules(),
            passValidationInfo: {
                number: null,
                validFrom: null,
                validTo: null,
                entries: []
            }
        };
    },
    mounted() {
    },
    computed: {
        recaptchaSiteKey() {
            return process.env.VUE_APP_RECAPTCHA_KEY;
        },
        validFrom() {
            if (this.passValidationInfo.validFrom) {
                let date = new Date(this.passValidationInfo.validFrom);
                return date.toLocaleDateString() + ' ' + ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2);
            }
            return null;
        },
        validTo() {
            if (this.passValidationInfo.validTo) {
                let date = new Date(this.passValidationInfo.validTo);
                return date.toLocaleDateString() + ' ' + ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2);
            }
            return null;
        }

    },
    methods: {
        onCaptchaVerified: function (recaptchaToken) {
            const self = this;
            self.$refs.recaptcha.reset();
            this.checkingPass = true;

            let url = process.env.VUE_APP_ROOT_API +
                process.env.VUE_APP_API_ROOT +
                "/pass/valid-info/" +
                process.env.VUE_APP_API_TENANT_ID;

            window.axios.post(url, { token: recaptchaToken, number: this.number })
                .then(response => {
                    this.errNotFound = false;
                    this.passValidationInfo = response.data;
                    this.checkingPass = false;
                })
                .catch(error => {
                    //alert(JSON.stringify(error));
                    this.checkingPass = false;
                    this.errNotFound = (error.status === 404);
                });
        },
        onCaptchaExpired: function () {
            this.$refs.recaptcha.reset();
        },
        validate() {
            if (this.$refs.checkForm.validate()) {
                this.$refs.recaptcha.execute();
            }
        }
    }
}
</script>
