<template>
  <v-snackbar v-model="snackbar.active" color="white" :timeout="-1"  multi-line>
    <div :style="{ color: textColor }" style="text-align: center;">{{ snackbar.message }}</div>
    <div style="margin-top: 10px;text-align: center;">
    <v-btn v-if="snackbar.button" variant="flat" class="white--text" color="black" dark @click="goToCheckout" >
      {{ $t("cart.toCheckout") }}
    </v-btn>
  </div>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Snackbar",
  computed: {
    ...mapGetters(["snackbar"]),
    textColor(){
      return this.snackbar.type == 'error' ? 'red' : 'black';
    }
  },  
  methods: {
    ...mapActions(["hideSnackbar"]),
    goToCheckout() {
      this.hideSnackbar();
      this.$router.push("/checkout");
    },
  },
};
</script>
