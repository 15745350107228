<template>
    <v-container fluid class="pa-0 footer-class">
        <links v-if="!isCheckout" />
        <footer-address />
    </v-container>
</template>

<script>
    import Links from "./Links"
    import Address from "./Address"

    export default {
        name: 'Footer',
        props: ['isCheckout'],
        components: {
            Links,
            'footer-address': Address
        }
    }
</script>

<style lang="scss">
    .footer-class {
        background-color: black;
    }
</style>
